function myCustomFileBrowser(field_name, url, type, win) {
  // Do custom browser logic
  alert("Hu lalala");
  win.document.forms[0].elements[field_name].value = 'my browser value';
}

window.activeTextArea = null;


(function() {
  tinymce.init({
    selector:'.tinymce',
    file_picker_types: 'file image media',
    file_browser_callback: "myCustomFileBrowser",
    // file_picker_callback: function(field_name, url, type, win){
    //     tinymce.activeEditor.windowManager.openUrl({
    //        title: 'Just a title',
    //        url: '/admin/resources/file_manager'
    //     });

    //     return false;
    // },
    image_title: true,
    menubar: false,
    valid_children : '+body[style]',
    imagetools_cors_hosts: ['localhost', 'googleapis.com', 'heroku.com'],
    imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions",

    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen visualblocks',
      'insertdatetime media table paste code help wordcount imagetools'
    ],
    toolbar: 'code undo redo | formatselect  | addResource image |' +
    'bold italic forecolor backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help',
    setup: function (editor) { // on the setup of the TinyMCE plugin, add a button with the alias 'addImage'
        editor.ui.registry.addButton('addResource', {
            text: 'Add Resource', // the text to display alongside the button
            icon: 'image', // the icon to display alongside the button
            onAction: function (_) {
                window.activeTextArea = editor; // onclick of this button, set the active textarea to that of which the button was pressed
                $ = window.$;
                window.Modal.Resources.run(function(event){
                  window.activeTextArea.insertContent('&nbsp; <img src="' + event.target.src + '" class="img-fluid" /> &nbsp;');
                });
                // $.get('/dashboard/resources/file_manager', function(data) {
                //   $("#tmp_modal").remove();
                //   $("body").append("<div id='tmp_modal'></div>");
                //   $("#tmp_modal").append(data);
                //   return $($("#tmp_modal .modal").first()).modal("show");
                // });
            }
        });
    },
    // image_list: function(success) {
    //   success([
    //     {title: 'Dog', value: 'mydog.jpg'},
    //     {title: 'Cat', value: 'mycat.gif'}
    //   ]);
    // }
  });
})();


